/* External Libraries */
import toast from 'react-hot-toast';
import { getActiveActor } from 'src/lib/user-client-wrapper';

// export async function getSubscription(data) {
//   const toastId = toast.loading('Hämtar bolagets prenumeration, ett ögonblick...');

//   const url = `/api/customer/getSubscription`;
//   const body = JSON.stringify(data);
//   return fetch(url, {
//     method: 'post',
//     headers: {
//       'Accepted-Header': 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((json) => {
//       toast.dismiss();
//       return json;
//     })
//     .catch((err) => {
//       toast.error(
//         `Ajdå, något gick fel när vi skulle hämta bolagets prenumeration. Försök igen eller kontakta oss på support. [${err.message}]`,
//         {
//           id: toastId
//         }
//       );
//       return {
//         error: true,
//         msg: `Failed to fetch url: ${url}`
//       };
//     });
// }

export async function getInvoices(data, t: (key: string, options?: any) => any) {
  const toastId = toast.loading(t('toasts.get-invoices-loading'));

  const url = `/api/customer/getInvoices`;
  const body = JSON.stringify(data);

  return fetch(url, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.get-invoices-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getCompanyTeam(data, t: (key: string, options?: any) => any) {
  const toastId = toast.loading(t('toasts.get-team-loading'));

  const url = `/api/customer/getTeamMembers`;
  const body = JSON.stringify(data);

  return fetch(url, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.get-team-error', { errorMsg: err.message }), {
        id: toastId
      });
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

// export async function updateSubscription(
//   activeActor: Actor | null,
//   interval: CustomerSubscriptionInterval,
//   plan: CustomerSubscriptionPlan,
//   invoiceEmail: string,
//   invoiceRef: string
// ) {
//   const body = JSON.stringify({ activeActor, interval, plan, invoiceEmail, invoiceRef });

//   return fetch(`/api/customer/updateSubscription`, {
//     method: 'post',
//     headers: {
//       'Accepted-Header': 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((json) => {
//       return json?.updActor;
//     })
//     .catch((err) => {
//       console.log(err);
//       return null;
//     });
// }

export async function addUpdateMember(
  newData,
  orgData,
  t: (key: string, options?: any) => any,
  deactivate = false,
  activate = false
) {
  const toastId = toast.loading(
    orgData ? t('toasts.update-member-loading') : t('toasts.invite-member-loading')
  );

  const activeActor = getActiveActor();

  const body = JSON.stringify({
    newData,
    orgData,
    activeActor,
    permission: newData?.permission,
    deactivate,
    activate
  });

  return fetch(`/api/customer/addUpdateMember`, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.success(
        orgData ? t('toasts.update-member-success') : t('toasts.invite-member-success'),
        {
          id: toastId
        }
      );
      return json;
    })
    .catch((err) => {
      toast.error(t('toasts.update-team-error', { errorMsg: err.message }), {
        id: toastId
      });
      return null;
    });
}

export async function addNewMember(
  newData,
  orgData,
  actor,
  t: (key: string, options?: any) => any,
  deactivate = false,
  activate = false
) {
  const toastId = toast.loading(
    orgData ? t('toasts.update-member-loading') : t('toasts.invite-member-loading')
  );

  const body = JSON.stringify({
    newData,
    orgData: null,
    addNewCo: true,
    activeActor: actor,
    permission: newData?.permission,
    deactivate,
    activate
  });

  return fetch(`/api/customer/addUpdateMember`, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.success(
        orgData ? t('toasts.update-member-success') : t('toasts.invite-member-success'),
        {
          id: toastId
        }
      );
      return json;
    })
    .catch((err) => {
      toast.error(
        t('toasts.update-team-error', {
          errorMsg: err.message
        }),
        {
          id: toastId
        }
      );
      return null;
    });
}
